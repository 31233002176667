<template>
  <v-container class="black">
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <shows />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
        md-offset-1
      >
        <v-card
          class="pa-3 mt-16"
          rounded
          dark
        >
          <v-carousel
            :show-arrows="false"
            :cycle="true"
            :hide-delimiters="true"
          >
            <v-carousel-item
              v-for="(item,i) in items"
              :key="i"
              :src="item.src"
              cover
            />
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {
      Shows: () => import('@/components/Shows.vue')
    },
    data () {
      return {
        items: [
          {
            src: '/static/incendiary-live-2023.jpg'
          },
          {
            src: '/static/t-birds-live-2023.jpg'
          },
          {
            src: '/static/fair-live-2023.jpg'
          },
          {
            src: '/static/band-ramkat.jpg'
          }
        ]
      }
    }
  }
</script>
